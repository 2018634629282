<template>
  <div class="mb-5 main-admissao fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Admissão de Alunos</h1>
    </b-jumbotron>
    <bread-cpv></bread-cpv>

    <b-container>
      <b-jumbotron class="bg-white p-0" style="text-align: center">
        <template #header>
          <span style="color: #333; font-size: 25.6px; font-weight: 600"
            >Será um prazer receber a sua família no Colégio CPV!</span
          >
        </template>
        <template #lead>
          <span style="color: #333; font-size: 19.2px !important">
            Nossas admissões ocorrem após um pequeno processo.
          </span>
        </template>
      </b-jumbotron>

      <hr />

      <b-row>
        <b-col lg="1"></b-col>
        <b-col sm="12" md="5" lg="5" xl="5">
          <h4 class="display-4" style="font-size: 18px !important; font-weight: 600">1º Passo:</h4>
          <div style="margin: 0px 0px !important">
            <h4>Visita</h4>
          </div>
          <b-row>
            <b-col sm="12" lg="12">
              <p class="text-justify">
                Na ocasião, apresentamos nosso projeto pedagógico e fazemos um passeio pelas instalações da Escola. O
                encontro é individualizado e é marcado após o preenchimento do formulário de contato, localizado no fim
                da página.
              </p>
            </b-col>
          </b-row>
        </b-col>
        <div
          class="line-vertical"
          style="border: 1px solid #f5f5f5 !important; margin-left: 0px; margin-right: 0px"
        ></div>
        <b-col class="b-col" sm="12" md="5" lg="5" xl="5">
          <h4 class="display-4" style="font-size: 18px !important; font-weight: 600">2º Passo:</h4>
          <div style="margin: 0px 0px !important">
            <h4>Matrícula</h4>
          </div>
          <b-row>
            <b-col sm="12" lg="12">
              <p class="text-justify">A família receberá as orientações para a Matrícula.</p>
              <p class="text-justify">Os critérios para preenchimento das vagas:</p>
              <ul>
                <li>1º - Irmão de alunos do Colégio CPV</li>
                <li>2º - Filhos de ex-alunos do Colégio CPV</li>
                <li>3º - Indicados por famílias do Colégio CPV</li>
                <li>4º - Data de Visita e Vivência</li>
              </ul>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <hr />

      <b-jumbotron class="bg-white text-center p-0 mt-5">
        <template #lead>
          <span style="font-size: 20px !important; font-weight: 700 !important">
            Após o preenchimento do formulário abaixo, entraremos em contato para agendar uma visita.
          </span>
        </template>
      </b-jumbotron>

      <b-form @submit.prevent="handleSubmit">
        <b-card
          class="mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados do Responsável</h5>
          </template>

          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group label="Nome" label-for="contato_nome" label-class="font-weight-bold required-field">
                <b-form-input name="contato_nome" id="contato_nome" type="text" v-model.trim="$v.contato.nome.$model" />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.contato.nome.$invalid && !$v.contato.nome.required"
                >
                  Campo Obrigatório
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.contato.nome.$invalid && !$v.contato.nome.alpha"
                >
                  O campo aceita apenas caracteres de a-Z, números e espaços.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" lg="6">
              <b-form-group label="Celular" label-for="contato_celular" label-class="font-weight-bold required-field">
                <the-mask
                  id="contato_celular"
                  name="contato_celular"
                  v-model.trim="$v.contato.celular.$model"
                  :mask="['(##) #####-####']"
                  type="text"
                  placeholder="(##) #####-####"
                  class="form-control"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.contato.celular.$invalid && !$v.contato.celular.required"
                >
                  Campo Obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6">
              <b-form-group label="E-Mail" label-for="contato_email" label-class="font-weight-bold required-field">
                <b-form-input
                  name="contato_email"
                  id="contato_email"
                  type="text"
                  v-model.trim="$v.contato.email.$model"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && $v.contato.email.$invalid && !$v.contato.email.required"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" lg="6">
              <b-form-group
                label="Como conheceu o CPV?"
                label-for="contato_conheceu_cpv"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  name="contato_conheceu_cpv"
                  id="contato_conheceu_cpv"
                  type="text"
                  v-model.trim="contato.como_conheceu"
                />
                <b-form-invalid-feedback
                  :force-show="
                    submitStatus == 'ERROR' && $v.contato.como_conheceu.$invalid && !$v.contato.como_conheceu.alpha
                  "
                >
                  O campo aceita apenas caracteres de a-Z, números e espaços.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group label-for="contato_tem_aluno_cpv" label-class="font-weight-bold">
                <template #label> &nbsp; </template>
                <b-form-checkbox
                  name="contato_tem_aluno_cpv"
                  id="contato_tem_aluno_cpv"
                  size="lg"
                  v-model.trim="contato.aluno_cpv"
                >
                  <small>Tenho outros filhos no Colégio CPV</small>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
        <b-card
          class="mt-3"
          header-bg-variant="transparent"
          footer-bg-variant="transparent"
          header-class="d-flex justify-content-between align-items-center"
          footer-class="d-flex justify-content-between align-items-center"
          v-for="(aluno, index) in $v.contato.alunos.$each.$iter"
          :key="index"
        >
          <template v-slot:header>
            <h5 class="mb-0">Dados do Aluno</h5>
          </template>

          <b-row>
            <b-col cols="12" sm="8" md="8">
              <b-form-group
                label="Nome"
                label-class="font-weight-bold required-field"
                :label-for="`contato_aluno_nome_${index}`"
              >
                <b-form-input
                  :id="`contato_aluno_nome_${index}`"
                  :name="`contato_aluno_nome_${index}`"
                  type="text"
                  v-model.trim="aluno.nome.$model"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && aluno.nome.$invalid && !aluno.nome.required"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && aluno.nome.$invalid && !aluno.nome.alpha"
                >
                  O campo aceita apenas caracteres de a-Z, números e espaços.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4" md="4">
              <b-form-group label="Dt. Nascimento" label-class="font-weight-bold required-field">
                <date-picker
                  :id="`contato_aluno_data_de_nascimento_${index}`"
                  :name="`contato_aluno_data_de_nascimento_${index}`"
                  v-model.trim="aluno.data_de_nascimento.$model"
                  lang="pt-br"
                  type="date"
                  value-type="YYYY-MM-DD"
                  format="DD-MM-YYYY"
                  input-class="cls-datepicker"
                  :editable="false"
                >
                  <template #footer="{ emit }">
                    <button class="mx-btn mx-btn-text" @click="emit(today)">Hoje</button>
                  </template>
                </date-picker>
                <b-form-invalid-feedback
                  :force-show="
                    submitStatus == 'ERROR' && aluno.data_de_nascimento.$invalid && !aluno.data_de_nascimento.required
                  "
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="4" md="4">
              <b-form-group
                label="Colégio atual"
                :label-for="`contato_aluno_colegio_atual_${index}`"
                label-class="font-weight-bold required-field"
              >
                <b-form-input
                  :id="`contato_aluno_colegio_atual_${index}`"
                  :name="`contato_aluno_colegio_atual_${index}`"
                  v-model.trim="aluno.colegio_atual.$model"
                />
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && aluno.colegio_atual.$invalid && !aluno.colegio_atual.required"
                >
                  Campo obrigatório
                </b-form-invalid-feedback>
                <b-form-invalid-feedback
                  :force-show="submitStatus == 'ERROR' && aluno.colegio_atual.$invalid && !aluno.colegio_atual.alpha"
                >
                  O campo aceita apenas caracteres de a-Z, números e espaços.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4" md="4">
              <b-form-group
                label="Ano letivo de interesse"
                :label-for="`contato_aluno_ano_interesse_${index}`"
                label-class="font-weight-bold required-field"
              >
                <b-form-select
                  :id="`contato_aluno_ano_interesse_${index}`"
                  :name="`contato_aluno_ano_interesse_${index}`"
                  v-model.trim="aluno.ano_interesse.$model"
                >
                  <option value="" disabled>Selecione o ano letivo</option>
                  <option v-for="ano in anosFuturos" :key="ano" :value="ano">{{ ano }}</option>
                </b-form-select>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && aluno.ano_interesse.$invalid">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="4" md="4">
              <b-form-group
                label="Série de interesse"
                :label-for="`contato_aluno_serie_interesse_${index}`"
                label-class="font-weight-bold required-field"
              >
                <b-form-select
                  v-model.trim="aluno.serie_interesse.$model"
                  :id="`contato_aluno_serie_interesse_${index}`"
                  :name="`contato_aluno_serie_interesse_${index}`"
                >
                  <option value="" disabled>Selecione a Série</option>
                  <option v-for="grade in grades" :key="grade.id" :value="grade.id">
                    {{ grade.name }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback :force-show="submitStatus == 'ERROR' && aluno.serie_interesse.$invalid">
                  Campo obrigatório
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-slot:footer>
            <b-link v-if="contato.alunos.length > 1" @click="removeStudent(index)" class="text-secondary">
              <i class="fa-solid fa-minus fa-xl mr-2"></i>Excluir Aluno
            </b-link>
            <span v-else> &nbsp;</span>
            <b-link v-if="index == contato.alunos.length - 1" @click="addStudent()" class="text-primary">
              <i class="fa-solid fa-plus fa-xl mr-2"></i>Adicionar Aluno
            </b-link>
          </template>
        </b-card>
        <b-row class="mt-3">
          <b-col>
            <b-button v-if="!submiting" block variant="primary" type="submit">Enviar</b-button>
            <b-button v-else block variant="primary">
              <i class="fa-solid fa-spinner fa-spin"></i>
              Enviando
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import BreadCpv from "../components/global/bread-cpv.vue";
import {
  FormPlugin,
  FormGroupPlugin,
  FormSelectPlugin,
  FormInputPlugin,
  FormTextareaPlugin,
  FormCheckboxPlugin,
} from "bootstrap-vue";
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormCheckboxPlugin);

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
// https://mengxiong10.github.io/vue2-datepicker/index.html
// https://github.com/mengxiong10/vue2-datepicker
import { TheMask } from "vue-the-mask";

// Import Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, email, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[a-zA-ZÀ-ü0-9' ]*$/);

import ApiService from "@/common/api.service";

export default {
  name: "AdmissaoCpv",
  metaInfo: { title: "Admissão de Alunos" },
  components: { BreadCpv, DatePicker, TheMask },
  data() {
    return {
      submitStatus: null,
      submiting: false,
      contato: {
        nome: null,
        celular: null,
        email: null,
        como_conheceu: null,
        aluno_cpv: false,
        alunos: [
          {
            nome: null,
            parentesco: "aluno",
            serie_interesse: null,
            colegio_atual: null,
            data_de_nascimento: null,
            ano_interesse: null,
          },
        ],
      },

      opt_parentesco: [
        { name: "Pai", nome: "Pai" },
        { value: "Mãe", nome: "Mãe" },
        { value: "aluno", nome: "Aluno" },
      ],
      tem_filho: [
        { tem_filho: "Sim", nome: "Sim" },
        { tem_filho: "Não", nome: "Não" },
      ],
      grades: [],
    };
  },
  computed: {
    anosFuturos() {
      const anoAtual = new Date().getFullYear();
      const anosFuturos = [];

      for (let i = 0; i < 3; i++) {
        anosFuturos.push((anoAtual + i).toString());
      }
      return anosFuturos;
    },
  },
  validations: {
    contato: {
      nome: {
        required,
        alpha,
      },
      celular: {
        required,
        numeric,
        minLength: minLength(11),
        maxLength: maxLength(11),
      },
      email: {
        email,
        required,
      },
      como_conheceu: {
        alpha,
      },
      alunos: {
        required,
        $each: {
          nome: { required, alpha },
          serie_interesse: { required },
          colegio_atual: { required, alpha },
          data_de_nascimento: { required },
          ano_interesse: { required, numeric },
        },
      },
    },
  },
  mounted: function () {
    this.getStudentAdmissionInitialData();
  },
  methods: {
    getStudentAdmissionInitialData: function () {
      ApiService.get("sitecpv/getStudentAdmissionInitialData")
        .then((response) => {
          if (response.data.status == "success") {
            this.grades = response.data.grades;
          } else {
            this.$snotify.error("Ocorreu um erro");
          }
        })
        .catch((errors) => {
          console.log(errors);
          this.$snotify.error("Ocorreu um erro");
        });
    },
    handleSubmit(e) {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        // do your submit logic here
        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submit();
          this.submitStatus = "OK";
        }, 500);
      }
    },
    submit: async function () {
      this.submiting = true;
      await ApiService.post("sitecpv/postStudentAdmission", {
        contato: this.contato,
      })
        .then((response) => {
          if (response.data.status == "success") {
            this.$snotify.success("Formulário de admissão enviado com sucesso");
            this.contato = {
              nome: null,
              celular: null,
              email: null,
              como_conheceu: null,
              aluno_cpv: false,
              alunos: [
                {
                  nome: null,
                  parentesco: "aluno",
                  serie_interesse: null,
                  colegio_atual: null,
                  data_de_nascimento: null,
                  ano_interesse: null,
                },
              ],
            };
            this.$v.$reset();
          } else {
            this.$snotify.error("Ocorreu um erro");
          }
        })
        .finally(() => {
          this.submiting = false;
        })
        .catch((errors) => {
          console.log(errors);
          this.$snotify.error("Ocorreu um erro");
        });
    },
    addStudent: function () {
      this.submitStatus = null;
      this.contato.alunos.push({
        nome: null,
        parentesco: "aluno",
        serie_interesse: null,
        colegio_atual: null,
        data_de_nascimento: null,
        ano_interesse: null,
      });
    },
    removeStudent: function (index) {
      this.contato.alunos.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.main-admissao {
  margin: 104.5px 0px 0px;
  font-family: "Open Sans";
  letter-spacing: -0.004em;
  .title_page {
    margin: 0px 0px -10px;
    padding: 50px 0px 45px;
    font-family: "Montserrat";
    background-repeat: repeat;
    background-position: center;
    background-color: #f16f31;
    background-image: url("../components/global/image/cpv-fundo-4.png");
    border-radius: 0px;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}
</style>
