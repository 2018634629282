<template>
  <div>
    <div
      class="jumbotron jumbotron-fluid bg-primary mb-5"
      style="
        background-color: #f16f31 !important;
        background-image: url('https://cpv.com.br/wp-content/uploads/2023/05/cpv-fundo-4.png');
        display: flex;
        justify-content: center;
        text-align: center;
      "
    >
      <h1 class="text-uppercase pt-0 pb-0 mt-3">Basquete Masculino</h1>
    </div>
    <b-breadcrumb style="margin-top: -48px; background-color: #f6f6f6; color: #aaaaaa">
      <b-container>
        <b-breadcrumb-item href="/">
          <strong>Colégio CPV</strong>
        </b-breadcrumb-item>
        <b-breadcrumb-item disabled>
          <strong>Atividades Complementares</strong>
        </b-breadcrumb-item>
        <b-breadcrumb-item class="text-uppercase" active> Basquete Masculino </b-breadcrumb-item>
      </b-container>
    </b-breadcrumb>

    <b-container>
      <blockquote>
        <span clas="mt-5 mb-5">
          Através da prática esportiva, o aluno desenvolve o condicionamento físico e competências socioemocionais.
        </span>
      </blockquote>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "basquete-masculino",
  template: "<h2>asdasdsad</h2>",
};
</script>

<style scoped>
blockquote {
  display: block;
  margin: 52px 0 47px;
  padding: 14px 35px 14px 37px;
  line-height: 16px;
  border-left: 10px solid #000;
}

blockquote:before {
  content: "";
  display: block;
  margin: 0px auto;
  position: absolute;
  width: 65%;
  height: 1px;
  z-index: 10;
  left: 75;
  background-color: #000;
}
</style>
