<template>
  <div class="w-100 d-block main-fundamental fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Ensino Médio</h1>
    </b-jumbotron>
    <bread-cpv></bread-cpv>

    <div class="article">
      <b-container md-fluid>
        <b-row>
          <b-col md="12" lg="7">
            <p class="text-justify">
              Nessa etapa final de aprofundamento e complementação dos conteúdos da educação básica, focamos em dois
              grandes pilares. Uma formação acadêmica forte e competitiva, technical skills, voltada ao acesso às
              universidades de renome e outra na consolidação das habilidades do século XXI, human skills, tão
              necessárias à futura vida profissional do nosso aluno, através de projetos desenvolvidos em duas trilhas
              de Empreendedorismo, em Ciências Humanas e em Ciências da Natureza, das quais o nosso aluno opta por uma.
            </p>

            <div class="text-center">
              <b-link class="btn btn-admissao" to="/admissao-de-novos-alunos"
                >Clique aqui para agendar a sua visita</b-link
              >
            </div>
          </b-col>
          <b-col md="12" lg="5" class="article-img"></b-col>
        </b-row>
      </b-container>
    </div>

    <div class="hours">
      <b-container>
        <b-row>
          <b-col sm="12" md="6" lg="4" v-for="(hours, index) in showHours.data" :key="index">
            <div class="hours-box">
              <i class="icon" :class="[hours.icon, hours.color, hours.bg_hours]"></i>
              <div>
                <span :class="[hours.color, hours.teste]">
                  {{ hours.hour }}
                </span>
                <p :class="hours.txt_color">{{ hours.text }}</p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-row>
      <b-col cols="12">
        <b-container class="content-matriz d-flex justify-content-center">
          <div class="box-matriz">
            <div class="header_box-matriz">
              <h2>Matriz</h2>
              <div class="line my-3">
                <div class="triangle"></div>
              </div>
            </div>

            <div class="table-mobile">
              <p class="mb-3 info">Clique sobre o Ano Escolar desejado e saiba mais sobre a matriz curricular.</p>
              <b-button v-b-toggle.sexto-ano class="w-100 button-collapse">1º ANO</b-button>
              <b-collapse id="sexto-ano" class="mt-2 mb-2" accordion="my-accordion">
                <div class="header">
                  <b-row>
                    <b-col cols="8" class="materias">Matéria</b-col>
                    <b-col cols="4" class="aulas"> Aulas </b-col>
                  </b-row>
                </div>
                <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                  <b-row class="bg-white">
                    <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                    <b-col
                      cols="4"
                      class="aulas"
                      v-for="(amounts, i) in matriz.amount"
                      :key="i"
                      v-show="amounts.quantityWeekly"
                    >
                      <div
                        v-show="
                          amounts.quantityWeekly === '2' ||
                          amounts.quantityWeekly === '3' ||
                          amounts.quantityWeekly === '1' ||
                          amounts.quantityWeekly === '4' ||
                          amounts.quantityWeekly === '5'
                        "
                      >
                        {{ amounts.quantityWeekly }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="footer-table">
                  <b-row class="bg-lightwhite">
                    <b-col cols="8" class="materia">Total</b-col>
                    <b-col cols="4" class="aulas">42</b-col>
                  </b-row>
                </div>
              </b-collapse>
              <b-button v-b-toggle.setimo-ano class="w-100 button-collapse mt-1">2º ANO</b-button>
              <b-collapse id="setimo-ano" class="mt-2 mb-2" accordion="my-accordion">
                <div class="header">
                  <b-row>
                    <b-col cols="8" class="materias">Matéria</b-col>
                    <b-col cols="4" class="aulas"> Aulas </b-col>
                  </b-row>
                </div>
                <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                  <b-row class="bg-white">
                    <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                    <b-col
                      cols="4"
                      class="aulas"
                      v-for="(amounts, i) in matriz.amount"
                      :key="i"
                      v-show="amounts.quantityWeekly"
                    >
                      <div
                        v-show="
                          amounts.quantityWeekly === '2' ||
                          amounts.quantityWeekly === '3' ||
                          amounts.quantityWeekly === '1' ||
                          amounts.quantityWeekly === '4' ||
                          amounts.quantityWeekly === '5'
                        "
                      >
                        {{ amounts.quantityWeekly }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="footer-table">
                  <b-row class="bg-lightwhite">
                    <b-col cols="8" class="materia">Total</b-col>
                    <b-col cols="4" class="aulas">42</b-col>
                  </b-row>
                </div>
              </b-collapse>
              <b-button v-b-toggle.oitavo-ano class="w-100 button-collapse mt-1">3º ANO</b-button>
              <b-collapse id="oitavo-ano" class="mt-2 mb-0" accordion="my-accordion">
                <div class="header">
                  <b-row>
                    <b-col cols="8" class="materias">Matéria</b-col>
                    <b-col cols="4" class="aulas"> Aulas </b-col>
                  </b-row>
                </div>
                <div class="body-table" v-for="(matriz, index) in showMatriz.data" :key="index">
                  <b-row class="bg-white">
                    <b-col cols="8" class="materia">{{ matriz.name }}</b-col>
                    <b-col
                      cols="4"
                      class="aulas"
                      v-for="(amounts, i) in matriz.amount"
                      :key="i"
                      v-show="amounts.quantityWeeklyTerceiro"
                    >
                      <div
                        v-show="
                          amounts.quantityWeeklyTerceiro === '2' ||
                          amounts.quantityWeeklyTerceiro === '3' ||
                          amounts.quantityWeeklyTerceiro === '1' ||
                          amounts.quantityWeeklyTerceiro === '4' ||
                          amounts.quantityWeeklyTerceiro === '5'
                        "
                      >
                        {{ amounts.quantityWeeklyTerceiro }}
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div class="footer-table">
                  <b-row class="bg-lightwhite">
                    <b-col cols="8" class="materia">Total</b-col>
                    <b-col cols="4" class="aulas">42</b-col>
                  </b-row>
                </div>
              </b-collapse>
            </div>

            <div class="table-desktop">
              <table class="w-100">
                <thead>
                  <tr bgcolor="#e8e8e8">
                    <th class="content">Componente Curricular</th>
                    <th class="serie first">1º Ano</th>
                    <th class="serie second">2º Ano</th>
                    <th class="serie third">3º Ano</th>
                  </tr>
                </thead>
                <tbody>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Álgebra</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                  </tr>
                  <tr>
                    <td class="content">Geometria</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Física</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr>
                    <td class="content">Química</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Biologia</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                    <td class="amount">4</td>
                  </tr>
                  <tr>
                    <td class="content">Português</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Redação</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr>
                    <td class="content">Literatura</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Inglês</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr>
                    <td class="content">Artes</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Educação Física</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr>
                    <td class="content">História</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Geografia</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                    <td class="amount">3</td>
                  </tr>
                  <tr>
                    <td class="content">Filosofia</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content">Sociologia</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                    <td class="amount">1</td>
                  </tr>
                  <tr>
                    <td class="content">
                      Empreendedorismo Ciências Humanas e <br />
                      <p class="mt-1 mb-0">Empreendedorismo Ciências da Natureza</p>
                    </td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                    <td class="amount">2</td>
                  </tr>
                  <tr bgcolor="#f6f6f6">
                    <td class="content no-border">Estudos Orientados</td>
                    <td class="amount no-border">5</td>
                    <td class="amount no-border">5</td>
                    <td class="amount no-border">5</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr bgcolor="#e8e8e8">
                    <th class="serie first">Total</th>
                    <td colspan="4" class="content">42 Aulas Semanais</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </b-container>
      </b-col>
    </b-row>

    <div class="agendamento">
      <b-container>
        <div class="text-center">
          <h3>Admissão de novos alunos</h3>
          <p>Agende sua visita e venha conhecer nossa escola!</p>
          <b-link class="btn btn-visita" to="/admissao-de-novos-alunos">Clique aqui para agendar a sua visita</b-link>
        </div>
      </b-container>
    </div>

    <div class="destaques">
      <div class="container-destaque">
        <header class="destaque-header">
          <h2>Destaques CPV</h2>
        </header>
        <div class="line my-3 mb-5">
          <div class="triangle"></div>
        </div>
        <b-row>
          <b-col
            class="mb-4"
            cols="12"
            sm="12"
            md="6"
            lg="4"
            xl="4"
            v-for="(destaques, index) in showDestaques.data"
            :key="index"
          >
            <div class="destaque-box" v-bind:class="destaques.color">
              <i class="icon" :class="[destaques.icon, destaques.box_icon]"></i>
              <h3>{{ destaques.name }}</h3>
              <p>{{ destaques.info }}</p>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { BTable } from "bootstrap-vue";
Vue.component("b-table", BTable);

import BreadCpv from "@/components/global/bread-cpv.vue";

export default {
  name: "EnsinoMedio",
  metaInfo: { title: "Ensino Médio 1ª e 2ª Série" },
  components: { BreadCpv },
  data: () => {
    return {
      showMatriz: {
        data: [
          {
            name: "Álgebra",
            amount: [{ quantityWeekly: "3" }, { quantityWeeklyTerceiro: "3" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Geometria",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "3" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Física",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Química",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Biologia",
            amount: [{ quantityWeekly: "4" }, { quantityWeeklyTerceiro: "4" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Português",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Redação",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Literatura",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Inglês",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Artes",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Educação Física",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "História",
            amount: [{ quantityWeekly: "3" }, { quantityWeeklyTerceiro: "3" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Geografia",
            amount: [{ quantityWeekly: "3" }, { quantityWeeklyTerceiro: "3" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Filosofia",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Sociologia",
            amount: [{ quantityWeekly: "1" }, { quantityWeeklyTerceiro: "1" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Empreendedorismo C. Humanas e C. Natureza",
            amount: [{ quantityWeekly: "2" }, { quantityWeeklyTerceiro: "2" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
          {
            name: "Estudos Orientados",
            amount: [{ quantityWeekly: "5" }, { quantityWeeklyTerceiro: "5" }],
            schoolYear: [{ name: "1º Ano" }, { name: "2º Ano" }, { name: "3º Ano" }],
          },
        ],
      },
      showDestaques: {
        data: [
          {
            name: "Língua Inglesa",
            info: "No ensino médio o ensino da língua inglesa é realizado com 2 aulas semanais, com turmas separadas por nível de conhecimento, completando a formação e preparando aos Certificados Cambridge (UK).",
            icon: "fa-duotone fa-file-certificate",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Apoio Pedagógico",
            info: "No apoio pedagógico as dúvidas são esclarecidas e os conteúdos reforçados com o objetivo de suprir possíveis defasagens de aprendizado e permitir o acompanhamento do conteúdo das aulas com qualidade. O atendimento é individual e com hora marcada.",
            icon: "fa-duotone fa-handshake",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Estudos Orientados",
            info: "Todas as aulas lecionadas no dia geram tarefas a serem entregues no mesmo dia, fortalecendo o aprendizado e consolidando o ritmo de estudos. Os Estudos Orientados, com a supervisão de um professor, organizam as tarefas, bem como as pesquisas e trabalhos da semana.",
            icon: "fa-solid fa-school",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Empreendedorismo",
            info: "Os cursos de Empreendedorismo organizam e capacitam os alunos ao enfrentamento de um problema real e interdisciplinar. Ao mobilizarem diferentes conhecimentos, utilizando metodologias ágeis em ambiente colaborativo, os alunos desenvolvem as habilidades do século XXI e fortalecem os seus projetos de vida.",
            icon: "fa-duotone fa-city",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Professores Assistentes",
            info: "Professores que acompanham o processo educativo, dando atendimento individualizado ao aluno, esclarecendo as dúvidas, orientando os estudos ou retomando determinados conteúdos.",
            icon: "fa-duotone fa-user-tie",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Projeto de Vida",
            info: "Além da orientação profissional, do Forúm de Profissões, das palestras e visitas às instituições de ensino, os alunos reforçam o seu projeto de vida através do autoconhecimento, conectando as suas trajetórias com as suas escolhas.",
            icon: "fa-duotone fa-list-check",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Alfred",
            info: "Instrumento de personalização e acompanhamento do aprendizado, a Plataforma monitorá os resultados das tarefas de aula, produções de textos, provas e simulados, permitindo o acompanhamento e a análise individualizada do desempenho escolar, bem como a auto regulação por parte do aluno.",
            icon: "fa-duotone fa-graduation-cap",
            color: "coral",
            box_icon: "box-coral",
          },
          {
            name: "Material Didático",
            info: "O material didático foi desenvolvido observando a proposta educacional do Colégio CPV, facilitando a integração das várias áreas do conhecimento e um aprendizado mais estruturado e intenso.",
            icon: "fa-duotone fa-books",
            color: "orange",
            box_icon: "box-orange",
          },
          {
            name: "Revisão",
            info: "No segundo semestre da terceira série, os alunos fazem uma revisão de todo o ensino médio com foco nos grandes vestibulares, em especial a Fuvest e o ENEM, garantindo a todos uma preparação robusta à aprovação da universidade desejada.",
            icon: "fa-duotone fa-file-pen",
            color: "roxo-b",
            box_icon: "box-roxo-b",
          },
          {
            name: "Projeto de Vida",
            info: "Além da orientação profissional, do Forúm de Profissões, das palestras e visitas às instituições de ensino, os alunos reforçam o seu projeto de vida através do autoconhecimento, conectando as suas trajetórias com as suas escolhas.",
            icon: "fa-duotone fa-list-check",
            color: "orange",
            box_icon: "box-orange",
          },
        ],
      },
      showHours: {
        data: [
          {
            hour: "42",
            text: "Aulas semanais",
            icon: "fa-duotone fa-calendars",
            color: "orange",
            bg_hours: "bg-orange",
            txt_color: "text-color",
          },
          {
            hour: "08 às 16h30",
            text: "Período integral",
            icon: "fa-duotone fa-clock-eight",
            color: "roxo-b",
            bg_hours: "bg-roxo-b",
            txt_color: "text-color",
          },
          {
            hour: "18h00",
            text: "Atividades Complementares",
            icon: "fa-duotone fa-compass-drafting",
            color: "coral",
            bg_hours: "bg-coral",
            txt_color: "text-color",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }

  .main-fundamental {
    .title_page {
      margin: 0px 0px 0px;
      padding: 50px 0px 40px;
      background-repeat: repeat;
      background-position: center;
      background-color: #f16f31;
      background-image: url("../components/global/image/cpv-fundo-4.png");
      border-radius: 0px;

      h1 {
        font-size: 51px;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .article {
      padding: 30px 0px;

      p {
        font-size: 15px;
        letter-spacing: 0.02em;
        line-height: 26px;
      }

      .btn-admissao {
        margin-top: 30px;
        background-color: #f16f31;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 16px;
        border: 2px solid #f16f31;

        &:hover {
          background-color: #f04b5d;
          border: 2px solid #f04b5d;
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.2);
          transition: all 0.3s ease-in-out;
        }
      }
      .article-img {
        background: #fff;

        width: 100%;
        height: 275px;

        border-top-left-radius: 32px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 12px;

        background-image: url("../components/global/image/20180522_100628.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 600px;

        border-top: 4px solid #3b1953;
        border-left: 3px solid #f16f31;
        border-right: 3px solid #f04b5d;
        border-bottom: 4px solid #623f99;

        box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.38);
      }
    }

    .hours {
      padding: 70px 0px 60px;
      .hours-box {
        display: flex;
        align-items: center;
        padding: 30px 15px;
        background: #fff;
        border-top-left-radius: 32px;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 5px;
        box-shadow: 0px 0px 15px rgba(1, 41, 112, 0.08);

        i {
          font-size: 42px;
          line-height: 0;
          margin-right: 20px;
          padding: 40px 20px;
          border-radius: 5px;
        }

        .bg-orange {
          background-color: rgba(241, 111, 49, 0.18);
        }
        .bg-roxo-b {
          background-color: rgba(98, 63, 153, 0.18);
        }
        .bg-coral {
          background-color: rgba(240, 75, 93, 0.18);
        }

        .text-color {
          color: #888888;
        }

        .orange {
          color: rgba(241, 111, 49, 1);
        }

        .roxo-b {
          color: rgb(98, 63, 153);
        }

        .coral {
          color: rgba(240, 75, 93, 1);
        }

        div {
          span {
            font-size: 34px;
            display: block;
            font-weight: 700;
          }
          p {
            padding: 0;
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
    }

    .content-matriz {
      height: auto;
      background-color: transparent;
      font-family: "Open Sans", sans-serif;
      padding: 0px 0px 50px;
      .box-matriz {
        width: 100%;
        .header_box-matriz {
          padding: 20px 0px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          text-align: center;
          h2 {
            font-weight: 700;
            font-size: 36px;
          }
          .line {
            border: 3px solid #f16f31;
            width: 8%;
            height: 3px;
            display: block;
            margin: 0px auto;
            position: relative;

            .triangle {
              border-bottom-color: #f16f31 !important;
              position: absolute;
              top: -10px;
              left: 45%;
              margin-left: -3px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #f16f31;
            }
          }
        }
      }

      .table-mobile {
        display: none;
      }
      .table-desktop {
        table {
          width: 100%;
          border: 0px !important;
          thead {
            tr {
              th {
                color: #f6f6f6;
                font-size: 0.9em;
                font-weight: 700;
                letter-spacing: 0.008em;
              }
              .content {
                padding: 8px 20px;
                background-color: #f16f31;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                text-transform: uppercase;
              }
              .serie {
                text-align: center;
                color: #f16f31;
                text-transform: uppercase;
              }
              .first,
              .third {
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
              }
              .second,
              .fourth {
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 8px 10px 8px 0px;
                color: #767676;
                font-size: 0.75em;
                // text-align: center;
                border-bottom: 1px solid #e8e8e8;
              }
              .content {
                padding: 8px 22px;
                // border-left: 1px solid #ccc;
                text-transform: uppercase;
                font-weight: 500;
              }
              .amount {
                text-align: center !important;
                border-right: 1px solid #e8e8e8;
                border-left: 1px solid #e8e8e8;
                font-weight: 600;
              }

              .no-border {
                border-bottom: 0px;
              }
            }
          }
          tfoot {
            tr {
              margin-top: 30px !important;
              td,
              th {
                padding: 8px 10px 8px 20px !important;
                font-size: 0.9em;
              }
              .serie {
                color: #f16f31;
                text-transform: uppercase;
              }
              .content {
                width: 125px;
                background-color: #f16f31;
                text-align: center;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                color: #f6f6f6;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.008em;
              }

              .first {
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
              }
            }
          }
        }
      }
    }

    .agendamento {
      background-color: #f16f31;
      background-size: cover;
      padding: 60px 0px;

      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 28px;
        text-transform: uppercase;
      }
      p {
        color: #fff;
      }

      .btn-visita {
        background-color: transparent;
        font-weight: 600;
        font-size: 14px !important;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 35px;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 16px;

        transition: 0.5s;
        margin-top: 10px;
        border: 2px solid #fff;
        color: #fff;
        text-transform: uppercase;
        &:hover {
          background-color: #ffff;
          color: #f16f31;
        }
      }
    }

    .destaques {
      padding: 60px 30px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .container-destaque {
        max-width: 1360px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .line {
          border: 3px solid #f16f31;
          width: 8%;
          height: 3px;
          display: block;
          margin: 0px auto;
          position: relative;

          .triangle {
            border-bottom-color: #f16f31 !important;
            position: absolute;
            top: -10px;
            left: 45%;
            margin-left: -3px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #f16f31;
          }
        }

        .destaque-header {
          text-align: center;
          padding-bottom: 0px;

          h2 {
            font-size: 38px;
            letter-spacing: 1px;
            font-weight: 700;
            margin: 0px 0px 0px;
            color: #333;
            text-transform: uppercase;
          }
        }
        .destaque-box {
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.08);
          height: 100%;
          padding: 60px 30px;
          text-align: center;
          transition: 0.3s;
          border-top-left-radius: 32px;
          border-bottom-right-radius: 32px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;

          .icon {
            font-size: 36px;
            padding: 40px 20px;
            border-radius: 4px;
            position: relative;
            margin-bottom: 25px;
            display: inline-block;
            line-height: 0;
            transition: 0.3s;
          }

          h3 {
            color: #333;
            font-weight: 700;
            font-size: 1.75rem;
            margin: 0 0 0.5rem;
            line-height: 1.8;
          }

          p {
            margin: 0 0 1rem;
          }

          .box-orange {
            background-color: rgba(241, 111, 49, 0.18);
            color: #f16f31;
          }
          .box-roxo-b {
            background-color: rgba(98, 64, 153, 0.18);
          }
          .box-coral {
            background-color: rgba(240, 75, 93, 0.18);
            color: #f04b5d;
          }
        }
      }
      .orange {
        border-bottom: 4px solid #f16f31;

        &:hover {
          background: #f16f31;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .roxo-b {
        border-bottom: 4px solid #623f99;

        &:hover {
          background: #623f99;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .coral {
        border-bottom: 4px solid #f04b5d;
        &:hover {
          background: #f04b5d;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }

  .main-fundamental {
    .title_page {
      margin: 100px 0px 55px !important;
      padding: 50px 0px 40px;
      background-repeat: repeat;
      background-position: center;
      background-color: #f16f31;
      background-image: url("../components/global/image/cpv-fundo-4.png");
      border-radius: 0px;

      h1 {
        font-size: 51px;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .article {
      padding: 30px 0px;

      p {
        font-size: 15px;
        letter-spacing: 0.02em;
        line-height: 26px;
      }

      .btn-admissao {
        margin-top: 30px;
        background-color: #f16f31;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 16px;
        border: 2px solid #f16f31;

        &:hover {
          background-color: #f04b5d;
          border: 2px solid #f04b5d;
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.34);
          transition: all 0.3s ease-in-out;
        }
      }
      .article-img {
        background: #fff;

        width: 100%;
        height: 325px;

        border-top-left-radius: 32px;
        border-top-right-radius: 12px;
        border-bottom-right-radius: 32px;
        border-bottom-left-radius: 12px;

        background-image: url("../components/global/image/20180522_100628.jpg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 600px;

        border-top: 4px solid #3b1953;
        border-left: 3px solid #f16f31;
        border-right: 3px solid #f04b5d;
        border-bottom: 4px solid #623f99;

        box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.38);
      }
    }

    .hours {
      padding: 70px 0px 60px;
      .hours-box {
        display: flex;
        align-items: center;
        padding: 30px 15px;
        background: #fff;
        box-shadow: 0px 0px 15px rgba(1, 41, 112, 0.08);

        i {
          font-size: 42px;
          line-height: 0;
          margin-right: 20px;
          padding: 40px 20px;
          border-radius: 5px;
        }

        .bg-orange {
          background-color: rgba(241, 111, 49, 0.18);
        }
        .bg-roxo-b {
          background-color: rgba(59, 25, 83, 0.18);
        }
        .bg-coral {
          background-color: rgba(240, 75, 93, 0.18);
        }

        .text-color {
          color: #888888;
        }

        .orange {
          color: rgba(241, 111, 49, 1);
        }

        .roxo-b {
          color: rgba(59, 25, 83, 1);
        }

        .coral {
          color: rgba(240, 75, 93, 1);
        }

        div {
          span {
            font-size: 26px;
            display: block;
            font-weight: 700;
          }
          p {
            padding: 0;
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
    }

    .content-matriz {
      height: auto;
      background-color: transparent;
      font-family: "Open Sans", sans-serif;
      padding: 50px 0px;
      .box-matriz {
        width: 95%;
        .header_box-matriz {
          padding: 20px 0px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          text-align: center;
          h2 {
            font-weight: 700;
            font-size: 36px;
          }
          .line {
            border: 3px solid #f16f31;
            width: 8%;
            height: 3px;
            display: block;
            margin: 0px auto;
            position: relative;

            .triangle {
              border-bottom-color: #f16f31 !important;
              position: absolute;
              top: -10px;
              left: 45%;
              margin-left: -3px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #f16f31;
            }
          }
        }
      }
      .table-mobile {
        display: none;
      }
      .table-desktop {
        table {
          width: 100%;
          border: 0px !important;
          thead {
            tr {
              th {
                color: #f6f6f6;
                font-size: 0.9em;
                font-weight: 700;
                letter-spacing: 0.008em;
              }
              .content {
                padding: 8px 20px;
                background-color: #f16f31;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                text-transform: uppercase;
              }
              .serie {
                text-align: center;
                color: #f16f31;
                text-transform: uppercase;
              }
              .first,
              .third {
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
              }
              .second,
              .fourth {
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 8px 10px 8px 0px;
                color: #767676;
                font-size: 0.75em;
                // text-align: center;
                border-bottom: 1px solid #e8e8e8;
              }
              .content {
                padding: 8px 22px;
                // border-left: 1px solid #ccc;
                text-transform: uppercase;
                font-weight: 500;
              }
              .amount {
                text-align: center !important;
                border-right: 1px solid #e8e8e8;
                border-left: 1px solid #e8e8e8;
                font-weight: 600;
              }

              .no-border {
                border-bottom: 0px;
              }
            }
          }
          tfoot {
            tr {
              margin-top: 30px !important;
              td,
              th {
                padding: 8px 10px 8px 20px !important;
                font-size: 0.9em;
              }
              .serie {
                color: #f16f31;
                text-transform: uppercase;
              }
              .content {
                width: 125px;
                background-color: #f16f31;
                text-align: center;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                color: #f6f6f6;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.008em;
              }

              .first {
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
              }
            }
          }
        }
      }
    }

    .agendamento {
      background-color: #f16f31;
      background-size: cover;
      padding: 60px 0px;

      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 28px;
        text-transform: uppercase;
      }
      p {
        color: #fff;
      }

      .btn-visita {
        background-color: transparent;
        font-weight: 600;
        font-size: 14px !important;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 35px;
        border-radius: 5px;
        transition: 0.5s;
        margin-top: 10px;
        border: 2px solid #fff;
        color: #fff;
        &:hover {
          background-color: #ffff;
          color: #f16f31;
        }
      }
    }

    .destaques {
      padding: 60px 30px;
      overflow: hidden;
      .container-destaque {
        max-width: 1360px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: -0%;

        .line {
          border: 3px solid #f16f31;
          width: 8%;
          height: 3px;
          display: block;
          margin: 0px auto;
          position: relative;

          .triangle {
            border-bottom-color: #f16f31 !important;
            position: absolute;
            top: -10px;
            left: 45%;
            margin-left: -3px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #f16f31;
          }
        }

        .destaque-header {
          text-align: center;
          padding-bottom: 0px;

          h2 {
            font-size: 38px;
            letter-spacing: 1px;
            font-weight: 700;
            margin: 0px 0px 0px;
            color: #333;
            text-transform: uppercase;
          }
        }
        .destaque-box {
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.08);
          height: 100%;
          padding: 60px 30px;
          text-align: center;
          transition: 0.3s;
          border-top-left-radius: 32px;
          border-bottom-right-radius: 32px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;

          .icon {
            font-size: 36px;
            padding: 40px 20px;
            border-radius: 4px;
            position: relative;
            margin-bottom: 25px;
            display: inline-block;
            line-height: 0;
            transition: 0.3s;
          }

          h3 {
            color: #333;
            font-weight: 700;
            font-size: 1.75rem;
            margin: 0 0 0.5rem;
            line-height: 1.8;
          }

          p {
            margin: 0 0 1rem;
          }

          .box-orange {
            background-color: rgba(241, 111, 49, 0.18);
            color: #f16f31;
          }
          .box-roxo-b {
            background-color: rgba(59, 25, 83, 0.18);
          }
          .box-coral {
            background-color: rgba(240, 75, 93, 0.18);
            color: #f04b5d;
          }
        }
      }
      .orange {
        border-bottom: 4px solid #f16f31;

        &:hover {
          background: #f16f31;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .roxo-b {
        border-bottom: 4px solid #623f99;

        &:hover {
          background: #623f99;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .coral {
        border-bottom: 4px solid #f04b5d;
        &:hover {
          background: #f04b5d;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }

  .main-fundamental {
    .title_page {
      margin: 100px 0px 55px !important;
      padding: 50px 0px 40px;
      background-repeat: repeat;
      background-position: center;
      background-color: #f16f31;
      background-image: url("../components/global/image/cpv-fundo-4.png");
      border-radius: 0px;

      h1 {
        font-size: 45px;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .article {
      padding: 30px 0px;

      p {
        font-size: 15px;
        letter-spacing: 0.02em;
        line-height: 26px;
      }

      .btn-admissao {
        margin-top: 30px;
        background-color: #f16f31;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 16px;
        border: 2px solid #f16f31;

        &:hover {
          background-color: #f04b5d;
          border: 2px solid #f04b5d;
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.34);
          transition: all 0.3s ease-in-out;
        }
      }
      .article-img {
        display: none;
      }
    }

    .hours {
      padding: 70px 0px 60px;
      .hours-box {
        display: flex;
        align-items: center;
        padding: 30px 15px;
        background: #fff;
        box-shadow: 0px 0px 15px rgba(1, 41, 112, 0.08);

        i {
          font-size: 42px;
          line-height: 0;
          margin-right: 20px;
          padding: 40px 20px;
          border-radius: 5px;
        }

        .bg-orange {
          background-color: rgba(241, 111, 49, 0.18);
        }
        .bg-roxo-b {
          background-color: rgba(59, 25, 83, 0.18);
        }
        .bg-coral {
          background-color: rgba(240, 75, 93, 0.18);
        }

        .text-color {
          color: #888888;
        }

        .orange {
          color: rgba(241, 111, 49, 1);
        }

        .roxo-b {
          color: rgba(59, 25, 83, 1);
        }

        .coral {
          color: rgba(240, 75, 93, 1);
        }

        div {
          span {
            font-size: 32px;
            display: block;
            font-weight: 700;
          }
          p {
            padding: 0;
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
    }

    .content-matriz {
      height: auto;
      background-color: transparent;
      font-family: "Open Sans", sans-serif;
      padding: 50px 0px;
      .box-matriz {
        width: 95%;
        .header_box-matriz {
          padding: 20px 0px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          text-align: center;
          h2 {
            font-weight: 700;
            font-size: 36px;
          }
          .line {
            border: 3px solid #f16f31;
            width: 12%;
            height: 3px;
            display: block;
            margin: 0px auto;
            position: relative;

            .triangle {
              border-bottom-color: #f16f31 !important;
              position: absolute;
              top: -10px;
              left: 45%;
              margin-left: -3px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #f16f31;
            }
          }
        }
      }
      .table-mobile {
        display: none;
      }
      .table-desktop {
        table {
          width: 100%;
          border: 0px !important;
          thead {
            tr {
              th {
                color: #f6f6f6;
                font-size: 0.9em;
                font-weight: 700;
                letter-spacing: 0.008em;
              }
              .content {
                padding: 8px 20px;
                background-color: #f16f31;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                text-transform: uppercase;
              }
              .serie {
                text-align: center;
                color: #f16f31;
                text-transform: uppercase;
              }
              .first,
              .third {
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
              }
              .second,
              .fourth {
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
              }
            }
          }
          tbody {
            tr {
              td {
                padding: 8px 10px 8px 0px;
                color: #767676;
                font-size: 0.75em;
                // text-align: center;
                border-bottom: 1px solid #e8e8e8;
              }
              .content {
                padding: 8px 22px;
                // border-left: 1px solid #ccc;
                text-transform: uppercase;
                font-weight: 500;
              }
              .amount {
                text-align: center !important;
                border-right: 1px solid #e8e8e8;
                border-left: 1px solid #e8e8e8;
                font-weight: 600;
              }

              .no-border {
                border-bottom: 0px;
              }
            }
          }
          tfoot {
            tr {
              margin-top: 30px !important;
              td,
              th {
                padding: 8px 10px 8px 20px !important;
                font-size: 0.9em;
              }
              .serie {
                color: #f16f31;
                text-transform: uppercase;
              }
              .content {
                width: 125px;
                background-color: #f16f31;
                text-align: center;
                border-top-left-radius: 20px;
                border-bottom-right-radius: 20px;
                color: #f6f6f6;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.008em;
              }

              .first {
                border-bottom-left-radius: 20px;
                border-top-right-radius: 20px;
              }
            }
          }
        }
      }
    }
    .agendamento {
      background-color: #f16f31;
      background-size: cover;
      padding: 60px 0px;

      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 28px;
        text-transform: uppercase;
      }
      p {
        color: #fff;
      }

      .btn-visita {
        background-color: transparent;
        font-weight: 600;
        font-size: 14px !important;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 35px;
        border-radius: 5px;
        transition: 0.5s;
        margin-top: 10px;
        border: 2px solid #fff;
        color: #fff;
        &:hover {
          background-color: #ffff;
          color: #f16f31;
        }
      }
    }

    .destaques {
      padding: 60px 30px;
      overflow: hidden;
      .container-destaque {
        max-width: 1360px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: 0%;

        .line {
          border: 3px solid #f16f31;
          width: 8%;
          height: 3px;
          display: block;
          margin: 0px auto;
          position: relative;

          .triangle {
            border-bottom-color: #f16f31 !important;
            position: absolute;
            top: -10px;
            left: 45%;
            margin-left: -3px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #f16f31;
          }
        }

        .destaque-header {
          text-align: center;
          padding-bottom: 0px;

          h2 {
            font-size: 38px;
            letter-spacing: 1px;
            font-weight: 700;
            margin: 0px 0px 0px;
            color: #333;
            text-transform: uppercase;
          }
        }
        .destaque-box {
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.08);
          height: 100%;
          padding: 60px 30px;
          text-align: center;
          transition: 0.3s;
          border-top-left-radius: 32px;
          border-bottom-right-radius: 32px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;

          .icon {
            font-size: 36px;
            padding: 40px 20px;
            border-radius: 4px;
            position: relative;
            margin-bottom: 25px;
            display: inline-block;
            line-height: 0;
            transition: 0.3s;
          }

          h3 {
            color: #333;
            font-weight: 700;
            font-size: 1.75rem;
            margin: 0 0 0.5rem;
            line-height: 1.8;
          }

          p {
            margin: 0 0 1rem;
          }

          .box-orange {
            background-color: rgba(241, 111, 49, 0.18);
            color: #f16f31;
          }
          .box-roxo-b {
            background-color: rgba(59, 25, 83, 0.18);
          }
          .box-coral {
            background-color: rgba(240, 75, 93, 0.18);
            color: #f04b5d;
          }
        }
      }
      .orange {
        border-bottom: 4px solid #f16f31;

        &:hover {
          background: #f16f31;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .roxo-b {
        border-bottom: 4px solid #623f99;

        &:hover {
          background: #623f99;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .coral {
        border-bottom: 4px solid #f04b5d;
        &:hover {
          background: #f04b5d;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }

  .main-fundamental {
    .title_page {
      margin: 0px 0px 0px;
      padding: 50px 0px 40px;
      background-repeat: repeat;
      background-position: center;
      background-color: #f16f31;
      background-image: url("../components/global/image/cpv-fundo-4.png");
      border-radius: 0px;

      h1 {
        font-size: 45px;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
      }
    }
  }

  .article {
    padding: 30px 0px;

    p {
      font-size: 15px;
      letter-spacing: 0.02em;
      line-height: 26px;
    }

    .btn-admissao {
      margin-top: 30px;
      background-color: #f16f31;
      color: #fff;
      text-transform: uppercase;
      font-weight: 600;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 16px;
      border: 2px solid #f16f31;

      &:hover {
        background-color: #f04b5d;
        border: 2px solid #f04b5d;
        box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.34);
        transition: all 0.3s ease-in-out;
      }
    }
    .article-img {
      display: none;
    }
  }

  .hours {
    padding: 70px 0px 60px;
    .hours-box {
      display: flex;
      align-items: center;
      padding: 30px 15px;
      background: #fff;
      box-shadow: 0px 0px 15px rgba(1, 41, 112, 0.08);

      i {
        font-size: 42px;
        line-height: 0;
        margin-right: 20px;
        padding: 40px 20px;
        border-radius: 5px;
      }

      .bg-orange {
        background-color: rgba(241, 111, 49, 0.18);
      }
      .bg-roxo-b {
        background-color: rgba(59, 25, 83, 0.18);
      }
      .bg-coral {
        background-color: rgba(240, 75, 93, 0.18);
      }

      .text-color {
        color: #888888;
      }

      .orange {
        color: rgba(241, 111, 49, 1);
      }

      .roxo-b {
        color: rgba(59, 25, 83, 1);
      }

      .coral {
        color: rgba(240, 75, 93, 1);
      }

      div {
        span {
          font-size: 32px;
          display: block;
          font-weight: 700;
        }
        p {
          padding: 0;
          margin: 0;
          font-size: 13px;
          text-transform: uppercase;
        }
      }
    }
  }

  .content-matriz {
    height: auto;
    background-color: transparent;
    font-family: "Open Sans", sans-serif;
    padding: 50px 0px;
    .box-matriz {
      width: 95%;
      .header_box-matriz {
        padding: 20px 0px;
        font-family: "Montserrat", sans-serif;
        text-transform: uppercase;
        text-align: center;
        h2 {
          font-weight: 700;
          font-size: 36px;
        }
        .line {
          border: 3px solid #f16f31;
          width: 12%;
          height: 3px;
          display: block;
          margin: 0px auto;
          position: relative;

          .triangle {
            border-bottom-color: #f16f31 !important;
            position: absolute;
            top: -10px;
            left: 45%;
            margin-left: -3px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #f16f31;
          }
        }
      }
    }

    .table-mobile {
      display: none;
    }
    .table-desktop {
      table {
        width: 100%;
        border: 0px !important;
        thead {
          tr {
            th {
              color: #f6f6f6;
              font-size: 0.9em;
              font-weight: 700;
              letter-spacing: 0.008em;
            }
            .content {
              padding: 8px 20px;
              background-color: #f16f31;
              border-top-left-radius: 20px;
              border-bottom-right-radius: 20px;
              text-transform: uppercase;
            }
            .serie {
              text-align: center;
              color: #f16f31;
              text-transform: uppercase;
            }
            .first,
            .third {
              border-bottom-left-radius: 20px;
              border-top-right-radius: 20px;
            }
            .second,
            .fourth {
              border-top-left-radius: 20px;
              border-bottom-right-radius: 20px;
            }
          }
        }
        tbody {
          tr {
            td {
              padding: 8px 10px 8px 0px;
              color: #767676;
              font-size: 0.75em;
              // text-align: center;
              border-bottom: 1px solid #e8e8e8;
            }
            .content {
              padding: 8px 22px;
              // border-left: 1px solid #ccc;
              text-transform: uppercase;
              font-weight: 500;
            }
            .amount {
              text-align: center !important;
              border-right: 1px solid #e8e8e8;
              border-left: 1px solid #e8e8e8;
              font-weight: 600;
            }

            .no-border {
              border-bottom: 0px;
            }
          }
        }
        tfoot {
          tr {
            margin-top: 30px !important;
            td,
            th {
              padding: 8px 10px 8px 20px !important;
              font-size: 0.9em;
            }
            .serie {
              color: #f16f31;
              text-transform: uppercase;
            }
            .content {
              width: 125px;
              background-color: #f16f31;
              text-align: center;
              border-top-left-radius: 20px;
              border-bottom-right-radius: 20px;
              color: #f6f6f6;
              text-transform: uppercase;
              font-weight: 700;
              letter-spacing: 0.008em;
            }

            .first {
              border-bottom-left-radius: 20px;
              border-top-right-radius: 20px;
            }
          }
        }
      }
    }
  }

  .agendamento {
    background-color: #f16f31;
    background-size: cover;
    padding: 60px 0px;

    h3 {
      color: #fff;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
    }

    .btn-visita {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px !important;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 35px;
      border-radius: 5px;
      transition: 0.5s;
      margin-top: 10px;
      border: 2px solid #fff;
      color: #fff;
      &:hover {
        background-color: #ffff;
        color: #f16f31;
      }
    }
  }

  .destaques {
    padding: 60px 30px;
    overflow: hidden;
    .container-destaque {
      max-width: 1360px !important;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-left: 0%;

      .line {
        border: 3px solid #f16f31;
        width: 8%;
        height: 3px;
        display: block;
        margin: 0px auto;
        position: relative;

        .triangle {
          border-bottom-color: #f16f31 !important;
          position: absolute;
          top: -10px;
          left: 45%;
          margin-left: -3px;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-bottom: 8px solid #f16f31;
        }
      }

      .destaque-header {
        text-align: center;
        padding-bottom: 0px;

        h2 {
          font-size: 38px;
          letter-spacing: 1px;
          font-weight: 700;
          margin: 0px 0px 0px;
          color: #333;
          text-transform: uppercase;
        }
      }
      .destaque-box {
        box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.08);
        height: 100%;
        padding: 60px 30px;
        text-align: center;
        transition: 0.3s;
        border-top-left-radius: 32px;
        border-bottom-right-radius: 32px;
        border-top-right-radius: 8px;
        border-bottom-left-radius: 8px;

        .icon {
          font-size: 36px;
          padding: 40px 20px;
          border-radius: 4px;
          position: relative;
          margin-bottom: 25px;
          display: inline-block;
          line-height: 0;
          transition: 0.3s;
        }

        h3 {
          color: #333;
          font-weight: 700;
          font-size: 1.75rem;
          margin: 0 0 0.5rem;
          line-height: 1.8;
        }

        p {
          margin: 0 0 1rem;
        }

        .box-orange {
          background-color: rgba(241, 111, 49, 0.18);
          color: #f16f31;
        }
        .box-roxo-b {
          background-color: rgba(59, 25, 83, 0.18);
        }
        .box-coral {
          background-color: rgba(240, 75, 93, 0.18);
          color: #f04b5d;
        }
      }
    }
    .orange {
      border-bottom: 4px solid #f16f31;

      &:hover {
        background: #f16f31;
        transition: all 0.7s ease;
        .icon {
          background-color: #fff;
        }
        h3,
        p {
          color: #fff;
        }
      }
    }
    .roxo-b {
      border-bottom: 4px solid #623f99;

      &:hover {
        background: #623f99;
        transition: all 0.7s ease;
        .icon {
          background-color: #fff !important;
        }
        h3,
        p {
          color: #fff;
        }
      }
    }
    .coral {
      border-bottom: 4px solid #f04b5d;
      &:hover {
        background: #f04b5d;
        transition: all 0.7s ease;
        .icon {
          background-color: #fff !important;
        }
        h3,
        p {
          color: #fff;
        }
      }
    }
  }
}
@media (min-width: 320px) and (max-width: 576px) {
  .fade-in-up {
    -webkit-animation: fadeIn 0.4s ease-in-out;
    -moz-animation: fadeIn 0.4s ease-in-out;
    -o-animation: fadeIn 0.4s ease-in-out;
    animation: fadeIn 0.4s ease-in-out;
  }

  .main-fundamental {
    .title_page {
      margin: 0px 0px 0px;
      padding: 50px 0px 40px;
      background-repeat: repeat;
      background-position: center;
      background-color: #f16f31;
      background-image: url("../components/global/image/cpv-fundo-4.png");
      border-radius: 0px;

      h1 {
        font-size: 45px;
        font-weight: 700;
        color: #ffffff;
        text-transform: uppercase;
      }
    }

    .article {
      padding: 30px 0px;

      p {
        font-size: 15px;
        letter-spacing: 0.02em;
        line-height: 26px;
      }

      .btn-admissao {
        margin-top: 30px;
        background-color: #f16f31;
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        border-top-left-radius: 16px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 16px;
        border: 2px solid #f16f31;

        &:hover {
          background-color: #f04b5d;
          border: 2px solid #f04b5d;
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.34);
          transition: all 0.3s ease-in-out;
        }
      }
      .article-img {
        display: none;
      }
    }

    .hours {
      padding: 70px 0px 60px;
      .hours-box {
        display: flex;
        align-items: center;
        padding: 30px 15px;
        background: #fff;
        box-shadow: 0px 0px 15px rgba(1, 41, 112, 0.08);

        i {
          font-size: 42px;
          line-height: 0;
          margin-right: 20px;
          padding: 40px 20px;
          border-radius: 5px;
        }

        .bg-orange {
          background-color: rgba(241, 111, 49, 0.18);
        }
        .bg-roxo-b {
          background-color: rgba(59, 25, 83, 0.18);
        }
        .bg-coral {
          background-color: rgba(240, 75, 93, 0.18);
        }

        .text-color {
          color: #888888;
        }

        .orange {
          color: rgba(241, 111, 49, 1);
        }

        .roxo-b {
          color: rgba(59, 25, 83, 1);
        }

        .coral {
          color: rgba(240, 75, 93, 1);
        }

        div {
          span {
            font-size: 32px;
            display: block;
            font-weight: 700;
          }
          p {
            padding: 0;
            margin: 0;
            font-size: 13px;
            text-transform: uppercase;
          }
        }
      }
    }
    .content-matriz {
      height: auto;
      background-color: transparent;
      font-family: "Open Sans", sans-serif;
      padding: 50px 0px;
      .box-matriz {
        width: 90%;
        .header_box-matriz {
          padding: 20px 0px;
          font-family: "Montserrat", sans-serif;
          text-transform: uppercase;
          text-align: center;
          h2 {
            font-weight: 700;
            font-size: 36px;
          }
          .line {
            border: 3px solid #f16f31;
            width: 25%;
            height: 3px;
            display: block;
            margin: 0px auto;
            position: relative;

            .triangle {
              border-bottom-color: #f16f31 !important;
              position: absolute;
              top: -10px;
              left: 45%;
              margin-left: -3px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-bottom: 8px solid #f16f31;
            }
          }
        }

        .table-desktop {
          display: none;
        }

        .table-mobile {
          display: block;
          .info {
            font-size: 0.8em;
            letter-spacing: 0.008em;
            font-weight: 600;
          }

          .button-collapse {
            border-radius: 5px;
            background-color: #f16f31;
            border: 1px solid #f16f31;
            font-weight: 700;

            &:focus,
            &:active {
              box-shadow: 0rem 0.005rem 0.4rem #ed550f;
              border: 1px solid #f16f31;
            }
          }
          .header {
            padding: 0px 15px;
            .materias {
              font-weight: 700;
              color: #f6f6f6;
              border-top-left-radius: 10px;
              border-bottom-right-radius: 10px;
              border: 1px solid #f16f31;
              background-color: #f16f31;
              padding: 5px 10px;
            }
            .aulas {
              text-align: center !important;
              font-weight: 700;
              color: #f16f31;
              background-color: #e8e8e8;
              border-bottom-left-radius: 10px;
              border-top-right-radius: 10px;
              padding: 5px 10px;
            }
          }
          .body-table {
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            padding: 0px 15px;

            font-size: 0.75em;
            color: #767676;
            text-transform: uppercase;
            .bg-lightwhite {
              background-color: #f6f6f6;
              padding: 10px 0px;
              border-bottom: 1px solid #e0e0e0;
            }
            .bg-white {
              padding: 10px 0px;
              border-bottom: 1px solid #e0e0e0;
            }
            .materia {
              font-weight: 500;
            }
            .aulas {
              text-align: center;
              font-weight: 600;
            }
          }

          .footer-table {
            padding: 0px 15px;
            .materia {
              font-weight: 700;
              color: #f16f31;
              background-color: #e8e8e8;
              border-top-right-radius: 10px;
              border-bottom-left-radius: 10px;
              padding: 5px 10px;
            }
            .aulas {
              color: #f6f6f6;
              border: 1px solid #f16f31;
              background-color: #f16f31;
              text-align: center !important;
              font-weight: 700;
              border-bottom-right-radius: 10px;
              border-top-left-radius: 10px;
              padding: 5px 10px;
            }
          }
        }
      }
    }

    .agendamento {
      background-color: #f16f31;
      background-size: cover;
      padding: 60px 0px;

      h3 {
        color: #fff;
        font-weight: 700;
        font-size: 28px;
        text-transform: uppercase;
      }
      p {
        color: #fff;
      }

      .btn-visita {
        background-color: transparent;
        font-weight: 600;
        font-size: 14px !important;
        letter-spacing: 1px;
        display: inline-block;
        padding: 10px 35px;
        border-radius: 5px;
        transition: 0.5s;
        margin-top: 10px;
        border: 2px solid #fff;
        color: #fff;
        &:hover {
          background-color: #ffff;
          color: #f16f31;
        }
      }
    }

    .destaques {
      padding: 60px 30px;
      overflow: hidden;
      .container-destaque {
        max-width: 1360px !important;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin-left: 0%;

        .line {
          border: 3px solid #f16f31;
          width: 8%;
          height: 3px;
          display: block;
          margin: 0px auto;
          position: relative;

          .triangle {
            border-bottom-color: #f16f31 !important;
            position: absolute;
            top: -10px;
            left: 45%;
            margin-left: -3px;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 8px solid #f16f31;
          }
        }

        .destaque-header {
          text-align: center;
          padding-bottom: 0px;

          h2 {
            font-size: 38px;
            letter-spacing: 1px;
            font-weight: 700;
            margin: 0px 0px 0px;
            color: #333;
            text-transform: uppercase;
          }
        }
        .destaque-box {
          box-shadow: 0px 0px 30px rgba(1, 41, 112, 0.08);
          height: 100%;
          padding: 60px 30px;
          text-align: center;
          transition: 0.3s;
          border-top-left-radius: 32px;
          border-bottom-right-radius: 32px;
          border-top-right-radius: 8px;
          border-bottom-left-radius: 8px;

          .icon {
            font-size: 36px;
            padding: 40px 20px;
            border-radius: 4px;
            position: relative;
            margin-bottom: 25px;
            display: inline-block;
            line-height: 0;
            transition: 0.3s;
          }

          h3 {
            color: #333;
            font-weight: 700;
            font-size: 1.75rem;
            margin: 0 0 0.5rem;
            line-height: 1.8;
          }

          p {
            margin: 0 0 1rem;
          }

          .box-orange {
            background-color: rgba(241, 111, 49, 0.18);
            color: #f16f31;
          }
          .box-roxo-b {
            background-color: rgba(59, 25, 83, 0.18);
          }
          .box-coral {
            background-color: rgba(240, 75, 93, 0.18);
            color: #f04b5d;
          }
        }
      }
      .orange {
        border-bottom: 4px solid #f16f31;

        &:hover {
          background: #f16f31;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .roxo-b {
        border-bottom: 4px solid #623f99;

        &:hover {
          background: #623f99;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
      .coral {
        border-bottom: 4px solid #f04b5d;
        &:hover {
          background: #f04b5d;
          transition: all 0.7s ease;
          .icon {
            background-color: #fff !important;
          }
          h3,
          p {
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
