<template>
  <div :class="{ 'custom-margin': isExtensivoRoute }">
    <div class="footer bg-footer pb-sm-2 pb-md-2 pt-3">
      <b-container>
        <div class="clearfix">
          <div class="float-left">
            <img
              src="https://mysociety.s3.amazonaws.com/logoCPV/logo-cpv.png"
              alt="Logo CPV"
              width="150"
              height="92"
              class="img-fluid"
            />
          </div>
        </div>
      </b-container>
      <hr />
      <b-container>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="4" class="pt-5">
            <b-row>
              <b-col cols="12" md="6" v-for="(items, index) in menu.menu_items" :key="index" class="pb-4 pt-2">
                <div class="mb-2">
                  <span class="font-weight-bold text-white h6">
                    {{ items.name }}
                  </span>
                </div>
                <div v-for="(subitem, indexsub) in items.sub_items" :key="indexsub" class="pb-1">
                  <b-link :to="{ name: subitem.route_name }" v-if="subitem.route_name" class="text-white text-link">
                    {{ subitem.name }}
                  </b-link>
                  <b-link v-else v-bind:href="subitem.path_url" class="text-white text-link">
                    {{ subitem.name }}
                  </b-link>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" sm="12" md="12" lg="8" class="pt-5">
            <b-row>
              <b-col cols="12" sm="12" md="4">
                <b-link
                  href="https://www.google.com.br/maps/place/R.+Domingos+Lopes+da+Silva,+34+-+Vila+Suzana,+S%C3%A3o+Paulo+-+SP,+05641-030/@-23.6176862,-46.7389012,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce578bf91f0b17:0x42417e3b15aa202a!8m2!3d-23.6176862!4d-46.7363209!16s%2Fg%2F11sm7xt51x?entry=ttu"
                  style="text-decoration: none"
                >
                  <div class="title-unity mb-2">
                    <span class="font-weight-bold text-white h6"> Unidade Morumbi </span>
                  </div>
                  <div class="contact-unity">
                    <span class="text-white">Rua Domingos Lopes da Silva, 34 São Paulo - SP</span>
                    <br />
                    <span class="text-white">CEP: 05641-030</span> <br />
                    <span class="text-white">Tel: (11) 3740-2111</span>
                  </div>
                </b-link>
              </b-col>
              <b-col cols="12" sm="12" md="4">
                <b-link
                  href="https://www.google.com.br/maps/place/Rua+Fiandeiras,+964+-+Itaim+Bibi,+S%C3%A3o+Paulo+-+SP,+04545-006/@-23.5950349,-46.68746,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce574f0be28cc1:0xbddc3a9d9807adc4!8m2!3d-23.595035!4d-46.6828519!16s%2Fg%2F11b8v83vsw?entry=ttu"
                  style="text-decoration: none"
                >
                  <div class="title-unity mb-2">
                    <span class="font-weight-bold text-white h6"> Unidade Vila Olímpia </span>
                  </div>
                  <div class="contact-unity">
                    <span class="text-white"
                      >Rua Fiandeiras, 964 <br />
                      São Paulo - SP</span
                    >
                    <br />
                    <span class="text-white">CEP: 04545-006</span> <br />
                    <span class="text-white">Tel: (11) 2344-4110</span>
                  </div>
                </b-link>
              </b-col>
              <b-col cols="12" sm="12" md="4">
                <b-link
                  href="https://www.google.com.br/maps/place/R.+da+Consola%C3%A7%C3%A3o,+1705+-+Consola%C3%A7%C3%A3o,+S%C3%A3o+Paulo+-+SP,+01301-100/@-23.5517312,-46.6594093,17z/data=!3m1!4b1!4m6!3m5!1s0x94ce5833e5eb9ab7:0xc712c1d9836b2114!8m2!3d-23.5517312!4d-46.656829!16s%2Fg%2F11csnlsj76?entry=ttu"
                  style="text-decoration: none"
                >
                  <div class="title-unity mb-2">
                    <span class="font-weight-bold text-white h6"> Unidade Consolação </span>
                  </div>
                  <div class="contact-unity">
                    <span class="text-white"
                      >Rua da Consolação, 1705 <br />
                      São Paulo - SP</span
                    >
                    <br />
                    <span class="text-white">01301-100</span> <br />
                    <span class="text-white">Tel: (11) 3123-4590</span>
                  </div>
                </b-link>
              </b-col>
            </b-row>
            <b-row class="mb-5" style="margin-top: 75px">
              <b-col cols="12" md="12" lg="12">
                <div class="title-social-medias d-flex justify-content-center mt-5 mb-2">
                  <span class="h3 font-weight-bold text-white"
                    ><span class="first-text">Siga-nos nas</span> <span class="second-text"> Redes Sociais</span></span
                  >
                </div>
                <div class="icons d-flex justify-content-center">
                  <a
                    href="https://www.instagram.com/cpveducacional"
                    class="font-icons d-flex align-items-center justify-content-center mr-3"
                  >
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                  <a
                    href="http://www.facebook.com/cpveducacional"
                    class="font-icons d-flex align-items-center justify-content-center ml-3 mr-3"
                  >
                    <i class="fa-brands fa-facebook-f"></i>
                  </a>
                  <a
                    href="http://www.twitter.com/cpveducacional"
                    class="font-icons d-flex align-items-center justify-content-center ml-3 mr-3"
                  >
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/cpvgrupoeducacional"
                    class="font-icons d-flex align-items-center justify-content-center ml-4"
                  >
                    <i class="fa-brands fa-youtube"></i>
                  </a>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import ApiService from "@/common/api.service";
export default {
  data() {
    return {
      menu: [],
    };
  },
  computed: {
    separatedBorder() {
      return {
        border: "1px solid #5B6970 !important",
      };
    },
    bgColor() {
      return {
        backgroundColor: "#465156 !important",
        width: "100%",
        padding: "25px 0px",
      };
    },
    isExtensivoRoute() {
      return this.$route.path.startsWith("/proximasturmas/");
    },
  },
  created() {
    this.getMenu();
  },
  methods: {
    getUrl() {
      let val = this.$route.path.split("/");
      if (val[1] == "home") {
        this.showSubscriber = false;
      } else {
        this.showSubscriber = true;
      }
    },
    getMenu: function () {
      ApiService.get(`sitecpv/getMenu/footer`)
        .then((response) => {
          this.menu = response.data.menu;
        })
        .catch((errors) => {
          console.log(errors);
        })
        .finally(() => {});
    },

    footerMargin() {
      return this.$route.name === "detalhe-do-curso" ? "220px" : "0px";
    },
  },
};
</script>
<style scoped>
.bg-footer {
  background-color: #414b50;
}

.footer hr {
  border: 0.5px solid #ffffff;
}

.custom-margin {
  margin-bottom: 123px;
}

.text-link {
  text-decoration: none;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.contact-unity span {
  font-size: 14px;
}

.first-text {
  color: #aaaaaa;
}

.second-text {
  color: #e5e5e5;
}

.icons .font-icons {
  background-color: #2d2e31;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  text-decoration: none;
}
.icons .font-icons i {
  font-size: 30px;
  color: #ffffff;
}

@media (min-width: 320px) and (max-width: 576px) {
  footer {
    padding-bottom: 3rem !important;
    height: 100%;
  }

  .custom-margin {
    margin-bottom: 118px;
  }

  .maps {
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .maps .card-maps {
    width: 315px;
    height: 100%;
  }

  .maps .card-maps iframe {
    width: 315px;
    height: 185px;
  }
}
</style>
