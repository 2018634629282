<template>
  <div class="main-error">
    <b-row>
      <b-col cols="12" sm="12" md="12">
        <img
          src="../components/global/image/CPV_educacional_positivo_sem_grafismo.webp"
          width="175"
          height="auto"
          class="fluid"
        />
      </b-col>
    </b-row>
    <b-row class="mt-3 mb-0">
      <b-col cols="12" sm="12" md="12">
        <h1 class="text-uppercase">Error 404</h1>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" sm="12" md="12">
        <p class="">Uh, oh, parece haver um erro aqui!</p>
      </b-col>
    </b-row>
    <b-row class="">
      <b-col cols="12" sm="12" md="12">
        <b-button class="btn-error404" to="/">
          <span>Home</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Error404",
};
</script>

<style lang="scss" scoped>
.main-error {
  margin-top: 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  background-color: #f6f6f6;

  width: auto;
  height: 550px;

  img {
    width: 175px;
    height: auto;
  }

  h1 {
    font-weight: 800;
    color: #f16f31;
    font-size: 3.5em;
  }

  p {
    font-weight: 600;
    color: #333333;
  }

  .btn-error404 {
    width: 250px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f16f31;
    border: 2px solid #f16f31;

    span {
      font-size: 1.2em;
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: 0.04em;
    }

    &:hover {
      box-shadow: 2px 2px 24px #a0a0a0;
      background-color: #ef4f05;
      border: 2px solid #ef4f05;
      color: #f6f6f6;
      transition: all 0.5s ease-in-out;
    }
  }
}
</style>
