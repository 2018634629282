<template>
  <div>
    <b-breadcrumb class="bg-light">
      <b-breadcrumb-item v-if="this.$route.meta.section_name == 'colegio'" :to="{ name: 'home-colegio' }">
        <strong class="text-uppercase">{{ colegio }}</strong>
      </b-breadcrumb-item>

      <b-breadcrumb-item class="text-uppercase" active>
        {{ $route.meta.title || title }}
      </b-breadcrumb-item>
    </b-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "BreadCpv",
  props: ["title", "url", "slug"],
  data() {
    return {
      breadcrumbList: [],
    };
  },
  components: {},

  created() {},
  computed: {
    mainSite: function () {
      if (this.$route.meta.section_name == "grupo") {
        return "grupo cpv";
      } else {
        return "GRUPO CPV";
      }
    },

    cursinho: function () {
      if (this.$route.meta.section_name == "cursinho") {
        return "cursinho cpv";
      } else {
        return null;
      }
    },
    colegio: function () {
      if (this.$route.meta.section_name == "colegio") {
        return "colégio cpv";
      } else {
        return null;
      }
    },
    areaCursinho: function () {
      if (this.$route.meta.section_childname == "proximasturmas") {
        return "Próximas Turmas";
      } else {
        return null;
      }
    },
    areaCurso: function () {
      if (this.$route.meta.section_childname == "curso") {
        return "PRÓXIMAS TURMAS";
      } else {
        return null;
      }
    },
  },

  // mainsite: function () {
  //   switch (this.$route.meta.section_name) {
  //     case "grupo":
  //       return "CPV"
  //       break
  //     case "cursinho":
  //       return "Cursinho CPV"
  //       break
  //     case "colegio":
  //       return "Colégio CPV"
  //       break
  //     default:
  //       return "CPV"
  //       break
  //   }
  // },
  // },
  // mounted() { this.updateList() },
  // watch: { '$route'() { this.updateList() } },
  // methods: {
  //   routeTo(pRouteTo) {
  //     if( this.breadcrumbList[pRouteTo].link ) {
  //       this.$router.push(this.breadcrumbList[pRouteTo].link)
  //     }
  //   },
  //   updateList() { this.breadcrumbList = this.$route.meta.breadcrumb }
  // },
};
</script>

<style scoped>
.bradcrumb-navigation {
  margin: 0px 0px 0px;
  background: #f6f6f6;
}
</style>
