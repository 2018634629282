<template>
  <div class="card-navigation">
    <b-row class="no-gutters">
      <b-col cols="12" sm="12" md="3"> </b-col>
      <b-col cols="12" sm="12" md="">
        <div class="general-cards fundamental">
          <b-link :to="{ name: 'fundamental' }" class="link-url">
            <div class="cards">
              <div class="icons">
                <i class="fa-solid fa-school"></i>
              </div>
              <div class="name-cards">
                <span>Ensino Fundamental</span>
              </div>
            </div>
          </b-link>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="3">
        <div class="general-cards medio">
          <b-link :to="{ name: 'medio' }" class="link-url">
            <div class="cards">
              <div class="icons">
                <i class="fa-duotone fa-graduation-cap"></i>
              </div>
              <div class="name-cards">
                <span>Ensino Médio</span>
              </div>
            </div>
          </b-link>
        </div>
      </b-col>
      <b-col cols="12" sm="12" md="3"> </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "CardsColegio",
  data: () => {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
/* 
  Desktop's responsives layout's set for card navigation.
  In the .cards, .icons and .name-cards classes they define CSS properties mobile layouts.
*/

@media (min-width: 1200px) {
  .card-navigation {
    margin: 0px 0px 0px;
  }

  .general-cards {
    border-radius: 8px;
    margin: 0px 0px 0px 0px;
  }

  .general-cards:hover {
    margin-top: -10px;
    transition: all 0.3s ease-in-out;
  }

  .link-url {
    text-decoration: none !important;
  }

  .cards {
    margin: 0;
    padding: 30px 25px;

    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    height: 175px;
  }

  .cards .icons {
    line-height: 58px;
  }

  .cards .icons > i {
    color: #fff;
    font-size: 3em;
  }

  .cards .name-cards > span {
    text-transform: uppercase;
    letter-spacing: -0.004em;
    font-weight: 700;

    color: #ffffff;
    font-size: 1.25em !important;
    text-align: center;
  }
}

/* 
  Mobile responsive layouts set for card navigation.
  In the .cards, .icons and .name-cards classes they define CSS properties desktop's layouts.
*/
@media (min-width: 992px) and (max-width: 1200px) {
  .card-navigation {
    margin: 0px 0px 0px;
  }

  .general-cards {
    border-radius: 8px;
    margin: 0px 0px 0px 0px;
  }

  .general-cards:hover {
    margin-top: -10px;
    transition: all 0.3s ease-in-out;
  }

  .link-url {
    text-decoration: none !important;
  }

  .cards {
    margin: 0;
    padding: 30px 0px;

    text-align: center;

    height: 186px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .cards .icons {
    line-height: 58px;
  }

  .cards .icons > i {
    color: #fff;
    font-size: 3em;
  }

  .cards .name-cards > span {
    text-transform: uppercase;
    letter-spacing: -0.004em;
    font-weight: 700;

    color: #ffffff;
    font-size: 1.4em !important;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .card-navigation {
    margin: 95px 0px 0px;
  }

  .general-cards {
    border-radius: 8px;
    margin: 0px 0px 0px 0px;
  }

  .general-cards:hover {
    margin-top: -10px;
    transition: all 0.3s ease-in-out;
  }

  .link-url {
    text-decoration: none !important;
  }

  .cards {
    margin: 0;
    padding: 30px 0px;

    text-align: center;

    height: 186px;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .cards .icons {
    line-height: 58px;
  }

  .cards .icons > i {
    color: #fff;
    font-size: 3em;
  }

  .cards .name-cards > span {
    text-transform: uppercase;
    letter-spacing: -0.004em;
    font-weight: 700;

    color: #ffffff;
    font-size: 1.4em !important;
    text-align: center;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .card-navigation {
    margin: 100px 0px 0px;
  }

  .link-url {
    text-decoration: none !important;
  }

  .cards {
    margin: 0;
    padding: 30px 0px;

    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .cards .icons {
    line-height: 58px;
  }

  .cards .icons > i {
    color: #fff;
    font-size: 2.5em;
  }

  .cards .name-cards > span {
    text-transform: uppercase;
    letter-spacing: -0.004em;
    font-weight: 700;

    color: #ffffff;
    font-size: 1em;
    text-align: center;
  }

  .medio,
  .cursinho,
  .sistema {
    padding: 10px 0px 0px;
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .card-navigation {
    margin: 10px 0px 0px;
  }

  .link-url {
    text-decoration: none !important;
  }

  .cards {
    margin: 0;
    padding: 30px 0px;

    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .cards .icons {
    line-height: 28px;
  }

  .cards .icons > i {
    color: #fff;
    font-size: 2.5em;
  }

  .cards .name-cards {
    line-height: 48px;
  }

  .cards .name-cards > span {
    text-transform: uppercase;
    letter-spacing: -0.004em;
    font-weight: 700;

    color: #ffffff;
    font-size: 1.2em;
  }
}

/* 
  Defined background-color cards navigation
*/
.fundamental {
  background-color: #1c745f;
}

.medio {
  background-color: #d74c21;
}

.cursinho {
  background-color: #42c1a0;
}

.sistema {
  background-color: #ff6622;
}
</style>
