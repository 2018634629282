<template>
  <div>
    <div class="down-banner">
      <CarouselSlide :slider-type="'colegio'" />
      <!-- <slider :slider-type="'colegio'"></slider> -->
    </div>

    <section id="price">
      <div class="container up-cards">
        <cardsHome />
      </div>
    </section>

    <section>
      <div class="agendamento">
        <b-container>
          <div class="text-center">
            <h3>Admissão de novos alunos</h3>
            <p>Agende sua visita e venha conhecer nossa escola!</p>
            <b-link
              class="btn btn-visita"
              :to="{ name: 'admissao-de-novos-alunos' }"
              @click="$trackEvent('buttonClick', { buttonName: 'admissao-colegio' })"
            >
              Clique aqui para agendar a sua visita</b-link
            >
          </div>
        </b-container>
      </div>
    </section>
  </div>
</template>

<script>
// import banner from '../components/global/banners.text'
// import slider from "../components/vueper/slider.vue";
import cardsHome from "./cards-home.vue";
// import AtividadesComplementares from "@/components/global/AtividadesComplementares.vue";
// import whatsappRD from "@/components/global/whatsappRD.txt";
import CarouselSlide from "@/components/vueper/carousel-slide.vue";

export default {
  name: "Index",
  components: {
    // banner,
    cardsHome,
    // AtividadesComplementares,
    // slider,
    CarouselSlide
  },
  created: () => {
    whatsappRD.computed();
  },
};
</script>

<style lang="scss" scoped>
.up-cards {
  margin-top: 0px;
  position: relative;
}

@media (min-width: 1200px) {
  .container {
    width: 100%;
    padding: 15px 0px;
    display: block;
    margin: 0px auto;
  }

  .up-cards {
    margin-top: 0px;
    position: relative;
  }

  #price {
    margin-bottom: 00px;
  }

  .parallax {
    margin: -0px 0px 0px;
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 65vh;

    .parallax-bg {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100%;
      background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/lousa21-3.webp");
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-position: center;
      // transform: translateZ(-50px);
    }
  }

  .agendamento {
    background-color: #f6f6f6;
    background-size: cover;
    padding: 60px 0px;

    h3 {
      color: #333;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
    }
    p {
      color: #333;
    }

    .btn-visita {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px !important;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 35px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 16px;

      transition: 0.5s;
      margin-top: 10px;
      border: 2px solid #f16f31;
      color: #f16f31;
      text-transform: uppercase;
      &:hover {
        background-color: #f16f31;
        color: #fff;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .container {
    width: 100%;
    padding: 15px 0px;
    display: block;
    margin: 0px auto;
  }

  .price {
    margin-top: 0px;
  }

  #price {
    margin: 0px -0px 00px;
  }

  .down-banner {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .parallax {
    margin: 0px 0px 0px;
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 75vh;

    .parallax-bg {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 75vh;
      background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/lousa21-3.webp");
      // background-attachment: fixed;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .agendamento {
    background-color: #f6f6f6;
    background-size: cover;
    padding: 60px 0px;

    h3 {
      color: #333333;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
    }
    p {
      color: #333333;
    }

    .btn-visita {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px !important;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 35px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 16px;

      transition: 0.5s;
      margin-top: 10px;
      border: 2px solid #f16f31;
      color: #f16f31;
      text-transform: uppercase;
      &:hover {
        background-color: #f16f31;
        color: #f16f31;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .container {
    width: 100%;
    padding: 15px 0px;
    display: block;
    margin: 0px auto;
  }

  .price {
    margin-top: 0px;
  }

  .up-cards {
    margin-top: -100px;
    position: relative;
  }

  #price {
    margin: 0px -0px 00px;
  }

  .down-banner {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .parallax {
    margin: 0px 0px 0px;
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 45vh;

    .parallax-bg {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 45vh;
      background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/lousa21-3.webp");
      // background-attachment: fixed;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .agendamento {
    background-color: #f6f6f6;
    background-size: cover;
    padding: 60px 0px;

    h3 {
      color: #333333;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
    }
    p {
      color: #333333;
    }

    .btn-visita {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px !important;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 35px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 16px;

      transition: 0.5s;
      margin-top: 10px;
      border: 2px solid #f16f31;
      color: #f16f31;
      text-transform: uppercase;
      &:hover {
        background-color: #ffff;
        color: #f16f31;
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .container {
    width: 100%;
    padding: 15px 0px;
    display: block;
    margin: 0px auto;
  }

  .price {
    margin-top: 0px;
  }

  .up-cards {
    margin-top: -100px;
    position: relative;
  }

  #price {
    margin: 0px -0px 00px;
  }

  .down-banner {
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .parallax {
    margin: 0px 0px 0px;
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 55vh;

    .parallax-bg {
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 55vh;
      background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/lousa21-3.webp");
      // background-attachment: fixed;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .agendamento {
    background-color: #f6f6f6;
    background-size: cover;
    padding: 60px 0px;

    h3 {
      color: #333333;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
    }
    p {
      color: #fff;
    }

    .btn-visita {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px !important;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 35px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 16px;

      transition: 0.5s;
      margin-top: 10px;
      border: 2px solid #fff;
      color: #fff;
      text-transform: uppercase;
      &:hover {
        background-color: #ffff;
        color: #f16f31;
      }
    }
  }
}

@media (min-width: 320px) and (max-width: 576px) {
  .container {
    width: 100%;
    padding: 0px 0px;
    display: block;
    margin: 0px auto;
  }

  .up-cards {
    margin-top: -30px;
    margin-bottom: -50px;
    position: relative;
    // z-index: 1024;
  }

  #price {
    margin: 30px -0px 0px;
  }

  .parallax {
    margin: 50px 0px 0px;
    position: relative;
    // overflow: hidden;
    width: 100%;
    height: 100vh;

    .parallax-bg {
      margin-top: 0px;
      position: absolute;
      overflow: hidden;
      width: 100%;
      height: 100vh;
      background-image: url("https://d1jehvsxq7tgfk.cloudfront.net/sitecpv/images/lousa21-3.webp");
      // background-attachment: fixed;
      // background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .agendamento {
    background-color: #f6f6f6;
    background-size: cover;
    padding: 60px 15px;

    h3 {
      color: #333333;
      font-weight: 700;
      font-size: 28px;
      text-transform: uppercase;
    }
    p {
      color: #333333;
    }

    .btn-visita {
      background-color: transparent;
      font-weight: 600;
      font-size: 14px !important;
      letter-spacing: 1px;
      display: inline-block;
      padding: 10px 35px;
      border-top-left-radius: 16px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 16px;

      transition: 0.5s;
      margin-top: 10px;
      border: 2px solid #f16f31;
      color: #f16f31;
      text-transform: uppercase;
      &:hover {
        background-color: #ffff;
        color: #f16f31;
      }
    }
  }
}
</style>
