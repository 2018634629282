<template>
  <div class="main-quem_somos-colegio fade-in-up">
    <b-jumbotron class="title-page mb-5 mt-5 title_page">
      <h1 class="text-center font-weight-bold">Quem somos</h1>
    </b-jumbotron>
    <bread-cpv></bread-cpv>

    <section class="intro-about">
      <b-row>
        <b-col>
          <b-container>
            <p class="text-justify">
              A proposta pedagógica do
              <span class="strong">Colégio CPV</span> tem o compromisso de promover desde os primeiros dias, uma
              educação integral e personalizada, capaz de formar pessoas aptas a empreenderem os seus talentos na
              construção de uma
              <span class="strong">vida humana digna, plena e sustentável</span>
              em um mundo cada vez mais globalizado e digital.
            </p>
            <p class="text-justify">
              O Desenvolvimento das competências e habilidades essenciais defendidas na
              <span class="strong">BNCC</span> ocorrerá em um
              <span class="strong">ambiente escolar abrangente, saudável e estimulante.</span>, Esse ambiente é
              supervisionado por uma equipe pedagógica administrativa engajada e motivada, que faz parte de um grupo
              educacional com mais de
              <span class="strong">seis décadas de atuação</span>
              na cidade de São Paulo.
            </p>
          </b-container>
        </b-col>
      </b-row>
    </section>
    <b-container class="timeline">
      <h1 class="text-center">Linha do Tempo CPV</h1>
      <ul>
        <li>
          <div class="content">
            <h2>
              <time>2022</time>
            </h2>
            <p class="text-justify">
              O Novo Ensino Médio é implantado com as trilhas optativas de Empreendedorismo em Ciências Humanas e
              Empreendedorismo em Ciências da Natureza, bem como uma adequação do Ensino Fundamental Anos Finais com os
              Projeto de Ciências da Natureza e Projeto de Ciências Humanas.
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <h2>
              <time>2017</time>
            </h2>
            <p class="text-justify">
              O Colégio CPV inaugura as turmas de 6º, 7º e 8º anos do Ensino Fundamental, visando à formação de
              estudantes com autonomia para um mundo em constante transformação.
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <h2>
              <time>2016</time>
            </h2>
            <p class="text-justify">
              Têm início as atividades do 9º ano do Ensino Fundamental, com uma proposta dinâmica e interativa que visa
              desenvolver a transição da fase infanto-juvenil para o Ensino Médio, um período de muita preparação,
              escolhas e decisões que tornarão os jovens aptos para enfrentar a sociedade pluralista da fase adulta.
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <h2>
              <time>2014</time>
            </h2>
            <p class="text-justify">
              Cria-se o Projeto de Interação e Sustentabilidade, que se apoia em conteúdos teóricos vistos em sala de
              aula para elaborar soluções para o desenvolvimento sustentável, com a criação de sistemas de compostagem,
              captação de água da chuva com cisternas, horta comunitária e proteção ao meio ambiente.
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <h2>
              <time>2013</time>
            </h2>
            <p class="text-justify">
              Institui-se o programa de Mentoria, oferecido pelo Setor de Orientação Educacional, visando auxiliar e
              esclarecer os estudantes quanto à escolha da carreira profissional por meio de discussões e dinâmicas
              variadas. Em paralelo, são agendadas visitas a Faculdades e Universidades.
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <h2>
              <time>2010</time>
            </h2>
            <p class="text-justify">
              Implanta-se o Curso de Empreendedorismo, que utiliza noções de Direito, Economia e Marketing para munir os
              alunos de informações para desenvolverem, com autonomia, um Plano de Negócios para a criação de uma
              empresa tradicional ou inovadora. O sucesso e o interesse dos alunos por esse curso levou à criação da
              Feira de Negócios, na qual os Projetos dos alunos são apresentados para pais, professores e convidados.
            </p>
          </div>
        </li>
        <li>
          <div class="content">
            <h2>
              <time>2007</time>
            </h2>
            <p class="text-justify">
              São introduzidas na matriz curricular as aulas de Atualidades, como forma de atender à demanda por
              relacionar as componentes curriculares com os acontecimentos geopolíticos e macrossociais e as novas
              tendências. Aliado a isso, o material didático do Sistema de Ensino CPV traz conteúdos atualizados
              anualmente.
            </p>
          </div>
        </li>
      </ul>
    </b-container>
  </div>
</template>

<script>
import BreadCpv from "@/components/global/bread-cpv.vue";

export default {
  name: "about",
  metaInfo: { title: "Quem Somos" },
  components: { BreadCpv },
  computed: {
    isSmallerDevice() {
      return window.innerWidth < 992;
    },
  },
};
</script>

<style lang="scss" scoped>
$title-background: #3d9e67;
$background-color: #faf8eb;
$content-background-color: #67cc8e;
$content-background-gradient: #56bc83;
$time-color: #fff;
$text-color: #fff;
$content-width: 450px;

.fade-in-up {
  -webkit-animation: fadeIn 0.4s ease-in-out;
  -moz-animation: fadeIn 0.4s ease-in-out;
  -o-animation: fadeIn 0.4s ease-in-out;
  animation: fadeIn 0.4s ease-in-out;
}

.main-quem_somos-colegio {
  .title_page {
    margin: 0px 0px 0px;
    padding: 50px 0px 40px;
    background-repeat: repeat;
    background-position: center;
    background-color: #f16f31;
    background-image: url("../components/global/image/cpv-fundo-4.png");
    border-radius: 0px;

    h1 {
      font-size: 51px;
      font-weight: 700;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
  .bradcrumb-navigation {
    margin: 0px 0px 0px;
    background: #f6f6f6;
  }
  .timeline {
    color: $text-color;

    h1,
    ul li .content h2 {
      text-shadow: 1px 1px 1px rgba(56, 56, 56, 0.5);
    }

    h1 {
      padding: 10px 0;
      font-size: 2.2em;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      color: #140b33;
    }

    ul {
      padding: 50px 0;

      li {
        background: #f16f31;
        position: relative;
        margin: 0 auto;
        width: 5px;
        padding-bottom: 40px;
        list-style-type: none;

        &:last-child {
          padding-bottom: 7px;
        }

        &:before {
          content: "";
          background: #f6f6f6;
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          -webkit-transform: translateX(-50%);
          width: 20px;
          height: 20px;
          border: 3px solid #f16f31;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
        }

        .hidden {
          opacity: 0;
        }

        .content {
          position: relative;
          top: 7px;
          width: $content-width;
          padding: 0px;
          box-shadow: 4px 4px 15px #ccc;
          border-radius: 8px;

          h2 {
            margin: 0px 0;
            background: #f16f31;
            color: $time-color;
            padding-bottom: 10px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: end;
            font-size: 1.8em;
            font-weight: 700;
          }

          p {
            text-align: center;
            border: 1px solid #ccc;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            color: #333;
            padding: 20px;
          }

          &:before {
            content: "";
            background: #f16f31;
            position: absolute;
            top: 0px;
            width: 38px;
            height: 5px;
          }
        }

        &:nth-child(odd) .content {
          left: 50px;
        }

        &:nth-child(odd) .content:before {
          left: -38px;
        }

        &:nth-child(even) .content {
          left: calc(-#{$content-width} - 45px);
        }

        &:nth-child(even) .content:before {
          right: -38px;
        }
      }
    }
  }
}

/* -------------------------
   ----- Media Queries ----- 
   ------------------------- */

@media screen and (max-width: 1020px) {
  .timeline ul li .content {
    width: 41vw !important;
  }
  .timeline ul li:nth-child(even) .content {
    left: calc(-41vw - 45px) !important;
  }
}

@media screen and (max-width: 700px) {
  .timeline ul li {
    margin-left: 20px !important;

    .content {
      width: calc(100vw - 100px) !important;

      h2 {
        text-align: initial !important;
      }
    }

    &:nth-child(even) .content {
      left: 45px !important;
    }

    &:nth-child(even) .content:before {
      left: -33px !important;
    }
  }
}
</style>
